import { prefStorage, Http } from '@samknows/utils';
import config from '@/common/config';
import * as measurementUtils from '@/common/helpers/measurements-utilities';
import viewTypes from '@/common/helpers/view-types';
import {
  PromiseResponse,
  METRIC_KEYS,
  Metric
} from '@/common/global.interfaces';
import { AccessiblePanel } from './Auth';

interface Values {
  id: string;
  identifier: string;
}

export interface Filterables {
  customDefined: boolean;
  id: string;
  identifier: string;
  metric: METRIC_KEYS[];
  type: string;
  units?: any /* eslint-disable-line @typescript-eslint/no-explicit-any */; // TODO [WEBPLAT-151] add types here
  values: Values;
}

export interface Splittables {
  id: string;
  identifier: string;
  metrics: METRIC_KEYS[];
}

interface PanelMetaData {
  filterables: Filterables;
  metrics: any[] /* eslint-disable-line @typescript-eslint/no-explicit-any */; // TODO [WEBPLAT-151] add types here
  splittables: Splittables;
}

interface UnitMetaData {
  filterables: Filterables;
  metrics: Metric[];
  splittables: Splittables;
}

export const getPanelMetadata = (
  panelId: number = prefStorage.get<AccessiblePanel>('panel').pid
): Promise<PromiseResponse<PanelMetaData>> => {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add return type for this HTTP call
  return Http.request<any>(
    `${config.api.analytics}/panel/${panelId}/analytics`
  ).then((data) => {
    data.data.metrics.forEach((metric) => {
      metric.type = measurementUtils.realTypeToUsefulType(metric.type);
    });

    const advancedSampleCounts = data.data.filterables.some(
      ({ id }) =>
        id.includes('_sample_count') &&
        id !== 'advanced_sample_count' &&
        !id.startsWith('daily_')
    );

    if (advancedSampleCounts) {
      data.data.filterables = data.data.filterables
        .filter(
          ({ id }) => !id.includes('_sample_count') || id.startsWith('daily_')
        )
        .map((filterable) => {
          if (filterable.id === 'sample_count') {
            filterable.id = 'advanced_sample_count';
            filterable.type = 'sample_count';
          }

          return filterable;
        });
    }

    return data;
  });
};

export const getUnitMetadata = (
  unitId: number = viewTypes.activeView.data.unitId
): Promise<PromiseResponse<UnitMetaData>> => {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ // TODO [WEBPLAT-151] add return types for this HTTP call
  return Http.request<any>(
    `${config.api.analytics}/units/${unitId}/metadata`
  ).then((data) => {
    data.data.metrics.forEach((metric) => {
      metric.type = measurementUtils.realTypeToUsefulType(metric.type);
    });

    return data;
  });
};
