import ZeusService from '@/common/services/Zeus';
import { UNIT_TEST_SCHEDULES_TASKS } from '@samknows/instant-test';
import { Unit } from '@/common/global.interfaces';
import { UnreachableCaseError } from '@/common/helpers/UnreachableCaseError';

export enum CallerType {
  INSTANT_TESTS = 'instant_tests',
  SCHEDULED_TESTS = 'scheduled_tests'
}

export enum TestScheduleEventType {
  SCHEDULED = 'SCHEDULED',
  STARTUP = 'STARTUP',
  MANUAL = 'MANUAL'
}

interface AvailableTest {
  calendars: { data: { event_type: TestScheduleEventType }[] };
  description: string;
  id: number;
  is_triggerable: boolean;
  options: unknown;
  task: { data: { name: UNIT_TEST_SCHEDULES_TASKS } };
}

export async function fetchAvailableTests(
  unit: Unit,
  callerType: CallerType
): Promise<UNIT_TEST_SCHEDULES_TASKS[]> {
  const BACKEND_PAGINATION = 50;

  // use panel default test schedule if none is set
  const lmapConfig =
    unit.lmap_config?.data?.id ?? unit.panel.data.default_lmap_config;

  const query: Record<string, unknown> = {
    include: 'task,calendars'
  };

  switch (callerType) {
    case CallerType.INSTANT_TESTS:
      query.isTriggerable = true;
      break;
    case CallerType.SCHEDULED_TESTS:
      query.perPage = BACKEND_PAGINATION;
      break;
    default:
      throw new UnreachableCaseError(callerType);
  }

  const { data } = await ZeusService.get(
    `panels/${unit.panel.data.id}/lmap/configs/${lmapConfig}/tests`,
    query
  );

  let filteredTests = data;

  if (callerType === CallerType.SCHEDULED_TESTS) {
    filteredTests = data.filter(isScheduledTest);
  }

  return filteredTests.map((test) => test.task.data.name);
}

function isScheduledTest(test: AvailableTest): boolean {
  return test.calendars.data.some(
    (calendar) => calendar.event_type === TestScheduleEventType.SCHEDULED
  );
}
